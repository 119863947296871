import * as React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import { chapterLink, chapterContainer } from '../components/layout.module.css'


const ByPartOfSpeech = ({data}) => {
  return (
    <Layout pageTitle="Index by Part of Speech">
        <div className={chapterContainer}>
        {data.partOfSpeechGroups.Part1.map(node => (
            <Link to={"/" + node.fieldValue} className={chapterLink}>{node.nodes.map(node => (<p>{node.frontmatter.partOfSpeech}</p>))}</Link>
        ))
      }
      </div>
    </Layout>
  )
}

export const query = graphql`
query partOfSpeechPage {
    partOfSpeechGroups: allMdx(sort: {fields: frontmatter___partOfSpeech, order: ASC}) {
        Part1: group(field: frontmatter___partOfSpeechSlug, limit: 1) {
          fieldValue
          nodes {
            frontmatter {
              sectionTitle
              title
              chapterTitle
              slug
              partOfSpeech
            }
          }
        }
      }
  }
`

export default ByPartOfSpeech